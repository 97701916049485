

body {
    background-color: rgba(209, 243, 190, 0.3);
    font-family: 'Lato', sans-serif;
}

.invite {
    align-items: center;
    margin: 30px;
}

.invite-h1 {
    position: relative;
    background-color: rgba(0,0,0,0);
    margin-top: 100px;
    bottom: 20px;
    text-align: center;
    font-size: 3vh;
    line-height: 0
}

.invite-h2 {
    background-color: rgba(0,0,0,0);
    text-align: center;
    font-size: 8vh;
    font-family: 'Arvo', serif;
    padding-top: 10px;
    padding-bottom: 30px;
    line-height: 1;
}

.invite-content {
    background-color: rgba(0,0,0,0);
    text-align: center;
    font-size: 3vh;
    line-height: 1.5;
}

.invite-links{
    justify-content: center;
}

.invite-links-RSVP {
    line-height: 1.5;
    text-align: center;
    font-size: 5vh;
    font-weight: bold;
    margin: 2vw;    
}

.invite-links-directions {
    line-height: 1;
    text-align: center;
    font-size: 3vh;
    font-weight: bold;
    margin: 2vw;    

}

.invite-final-details {
    background-color: rgba(0,0,0,0);
    text-align: center;
    font-size: 2vh;
    padding-bottom: 10px;

}