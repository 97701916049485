.Arbs-Calcs {
    margin: 0; /* Reset default margin to ensure full coverage */
    padding: 0; /* Reset default padding to ensure full coverage */
    text-align: left;
}

.Arbs-Calcs-heading {
    font-family: 'Architects Daughter', cursive;
    font-style: italic;
    text-align: left;
    font-weight:900;
    font-size: calc(20px + 1vmin);
    line-height: calc(20px + 6vmin);;
}

.Arbs-Calcs-Each-Side {
    display: inline-flex;
    text-align: left;
    justify-content: left;
    padding-left: 2vw;
    padding-top: 1vh;
    align-items: center;
}

.input-box {
    display:flex;
    width: 15%;
    border: none;
    border-bottom: 1px solid #282c3483;
    text-align: center;
}

.reset-button {
    display: flexbox;
    text-align: right;
    justify-content: right;
    padding-right: 2vw;
    text-decoration: underline;
    line-height: calc(0px + 3vmin);;
}