.rsvp-list {
    position: relative;
    margin: 6vw;
    color: #282c34;
}

.rsvp-list h1 {
    color: #282c34;
    font-weight: bolder;
    display: flex;
    justify-content: left;
}

.rsvp-table {
    position: relative;
    display: flex;
    justify-content: left;
    align-items: center;
    text-align: left;
}

.rsvp-table table {
    min-width: 30%;
}