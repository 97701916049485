.mask {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  position: fixed;
}
.login {
  width: 400px;
  border-radius: 10px;
  background-color: white;
  padding:10px;
}