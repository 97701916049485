.Arbs {
    background-color: #282c34;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0; /* Reset default margin to ensure full coverage */
    padding: 0; /* Reset default padding to ensure full coverage */
    padding-left: 5vw;
    padding-right: 5vw;
    font-family: 'Architects Daughter', cursive;
    color: white; /* Change link color */
}

.Arbs-header {
    min-height: 15vh;
    font-size: calc(20px + 4vmin);;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}

.Arbs-checkboxes {
    display: flex;
    flex-wrap: wrap;
    font-family: 'Segoe UI';
    width: 90vw;
    flex-direction: row;
    /*justify-content: center; */
    max-height: 200px; /* Set the desired max height */
    overflow-x:hidden;
    overflow-y: auto;
    margin-bottom: 3vh;
    /*column-count: 2; /* Set the gap between columns */
    column-gap: 10px; /* Set the gap between columns */
}

.checkbox-label {
    display: block;
    margin-bottom: 10px; /* Adjust as needed */
    margin-right: 20px; /* Adjust as needed */
  }
  
.Arbs-checkbox-container {
    max-height: 200px; /* Set the desired max height */
  }
  
.Arbs-checkbox-column {
    /* max-width: 80%; /* Set the maximum width of each column */
    padding: 10px;
  }

.arbsTable {
  background: #fafafa;
  font-family: 'Segoe UI';
  margin-top: 10px;
  padding: 10px;
  overflow-x: auto;
}

.arbsCalcs {
  display: flex;
  margin: 10px;
  padding: 10px;
  justify-self: center;
}

.findArbsButton{
  display: flex;
  justify-content: flex-start;
  margin-left: 40px;
  padding: 5px;
}
.findArbsButton button{ 
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

@media (max-width: 300px) {
  .Arbs-checkbox-column {
    flex: 1 0 100%;
  }
}

/*
@media (min-width: 301px) {
  .Arbs-checkbox-column {
    flex: 1 0 33.33%;
  }
} */

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(40, 44, 52, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
  
.modal-content {
  max-width: 50vw;
  max-height: 60vh;
  background-color: white;
  padding: 25px;
  color: black; /* Change link color */
  justify-content: top;
  align-items: center;
  border-radius: 20px;
  font-family: Arial;
  text-align: center;
  line-height: 150%;
}
