
.MannaOnlineMenu {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #282c34;
    margin: 0; /* Reset default margin to ensure full coverage */
    padding: 0; /* Reset default padding to ensure full coverage */
    font-family: 'Architects Daughter', cursive;
    text-align: center;
    color: white; /* Change link color */
}
  
.MannaOnlineMenu-header {
    min-height: 40vh;
    font-size: calc(100px + 8vmin);;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.MannaOnlineMenu ul {
    list-style: none; /* Remove default list bullets */
    padding: 0; /* Remove default padding from the list */
  }
  
  .MannaOnlineMenu li {
    font-size: 18px; /* Change to your desired font size */
    margin-bottom: 10px; /* Add space between list items */
    font-size: calc(20px + 4vmin);
  }
  
  .MannaOnlineMenu a {
    text-decoration: none; /* Remove underline from links */
    color: white; /* Change link color */
  }
  
  .MannaOnlineMenu a:hover {
    color: #ee4545; /* Change link color on hover */
  }