.Stocklist {
    background-color: #282c34;
    margin: 0; /* Reset default margin to ensure full coverage */
    padding: 0; /* Reset default padding to ensure full coverage */
    font-family: 'Architects Daughter', cursive;
    color: white; /* Change link color */
}

.StockList-header {
    min-height: 15vh;
    font-size: calc(20px + 4vmin);;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding-left: 5vw;
}

.StockList-iframe-container iframe {
    width: 100%;
    height: 80vh;
}