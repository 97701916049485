h1 {
    display: table;
    margin: 40px auto;
    color: #fff;
    font: 30px Helvetica;
    letter-spacing: 2px;
    text-transform: uppercase;
    letter-spacing: 10px;
  }
  
  form {
    display: table;
    margin: 40px auto;
  
    input,
    textarea {
      font: 18px Helvetica, Arial, sans-serif;
      box-sizing: border-box;
      display: block;
      border: none;
      padding: 20px;
      width: 300px;
      margin-bottom: 20px;
      font-size: 18px;
      outline: none;
      transition: all 0.2s ease-in-out;
  
      &::placeholder {
        transition: all 0.2s ease-in-out;
        color: #999;
        font: 18px Helvetica, Arial, sans-serif;
      }
  
      &:focus,
      &.populated {
        padding-top: 28px;
        padding-bottom: 12px;
  
        &::placeholder {
          color: transparent;
        }
      }
    }
  
    textarea {
      height: 200px;
    }
  
    button {
      transition: all 0.2s ease-in-out;
      font: 18px Helvetica, Arial, sans-serif;
      border: none;
      background: #1792E8;
      color: #fff;
      padding: 20px;
      width: 100%;
      text-transform: uppercase;
      letter-spacing: 5px;
  
      &:hover {
        background: #0e82d4;
        cursor: pointer;
      }
    }
  }