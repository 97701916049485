.invite-header-left {
    background-color: rgba(0,0,0,0);
    position: fixed;
    width: 30%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: left; /* left align */
    align-items: center; /* Center the image vertically */
}

.invite-header-right {
    background-color: rgba(0,0,0,0);
    position: fixed;
    width: 30%;
    top: 0;
    right: 0;
    display: flex;
    justify-content: right; /* right align*/
    align-items: center; /* Center the image vertically */
}

.invite-header img {
    max-width: 30%; /* Set a maximum width for the image */
    height: auto; /* Maintain the original aspect ratio */
}