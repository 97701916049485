.invite-footer {
    background-color: rgba(0,0,0,0);
    position: fixed;
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: center; /* Center the image horizontally */
    align-items: center; /* Center the image vertically */
}

.invite-footer img {
    max-width: 100%; /* Set a maximum width for the image */
    height: auto; /* Maintain the original aspect ratio */
}